import Navbar from './components/ecommerce/components/store-navigation/with_featured_categories'
import Hero from './components/marketing/sections/heroes/simple_centered'

function App(){
  return (
    <>
      <Navbar />
      <Hero />
    </>
  )
}

export default App;